<template>
  <!-- player-image -->
  <div  class="player-image" :class="{'full-screen': isFull}" style="height: 100%;overflow-x:hidden; overscroll-behavior: none;">
    <iframe ref="iframeViewer"
            :src="htmlUrl"
            :data-lrner-id="session.lrnerId"
            style="width:100%; height:100%;overflow-x:hidden; overscroll-behavior: none;"

    />

  </div>
  <!-- player-controller -->

</template>



<script>
import {computed, reactive, onMounted, ref, onUnmounted, onBeforeMount} from 'vue';
import {useStore} from 'vuex';
import {getItem, getVideoUrl, isSuccess, lengthCheck, setParams, stringCheck} from '@/assets/js/util';
import {useAlert} from '@/assets/js/modules/common/alert';
import {insertLearnProgressLog, insertLearnProgressResult} from '@/assets/js/modules/learn/learn-common';
import {eventCdDcds, getUserProgress} from '@/assets/js/modules/hrd/hrd-common';
import navigationUtils from '@/assets/js/navigationUtils';

export default {
  name: 'LearnHtml',
  components: {},
  props: {
    cms: Object,
    uuid: String,
  },
  setup(props) {

    const store = useStore();
    const {showLoading, hideLoading} = useAlert();
    const session = computed(() => store.state.auth.session);
    const inyn = ref('N');

    const isLoading = ref(true);
    const progRsltSn = ref(0);
    const user = reactive({progRsltSn: 0, actPlaySec:0 ,maxPlaySec: 0, lastPlaySec: 0, cmptnYn: 'N', mdfcnDt: null, regDt: null});
    showLoading();

    const objectParams = computed(() => {
      return {
        distCrseSn: props.cms.distCrseSn,
        distChapSn: props.cms.distChapSn,
        lrnObjDtlDistSn: props.cms.lrnObjDtlDistSn,
        objSn: props.cms.objSn,
        division: 'html',
      };
    });

       const getProgress = async ()=> {

         const userProgressRes = await getUserProgress(objectParams)
            console.log(userProgressRes);
         if (lengthCheck(userProgressRes)) {
           setParams(user, getItem(userProgressRes));
           // 로그이력 확인후 파라미터로 별도 페이지 전달  cmptnYn == 'Y'인 경우 페이지 학습 종료 /
         } else {
           receiveMessage();//초기화
         }
       }



      const htmlUrl = ref('');

      const makehtmlUrl = async () =>  {

        await getProgress();
        const playSrc = getVideoUrl(session.value.itn, props.cms);
        if(session.value.itn){
          inyn.value = 'Y';
        }

        let queryString= '';
        //&lrnerId=3331340&progSesnKey=ABCD&distCrseSn=3000728&lrnObjDtlDistSn=269126&objSn=146811&resultPage=A
        if (user.cmptnYn ==='Y'){
          queryString = `lrnerId=${encodeURIComponent(session.value.lrnerId)}&progSesnKey=${encodeURIComponent(props.uuid)}&distCrseSn=${encodeURIComponent(props.cms.distCrseSn)}&lrnObjDtlDistSn=${encodeURIComponent(props.cms.lrnObjDtlDistSn)}&objSn=${encodeURIComponent(props.cms.cttMstSn)}&inyn=${encodeURIComponent(inyn.value )}&resultPage=${encodeURIComponent(user.actPlaySec)}`;
        }else{
          queryString =  `lrnerId=${encodeURIComponent(session.value.lrnerId)}&progSesnKey=${encodeURIComponent(props.uuid)}&distCrseSn=${encodeURIComponent(props.cms.distCrseSn)}&lrnObjDtlDistSn=${encodeURIComponent(props.cms.lrnObjDtlDistSn)}&objSn=${encodeURIComponent(props.cms.cttMstSn)}&inyn=${encodeURIComponent(inyn.value)}&resultPage=`;
        }

        if (stringCheck(playSrc)) {
          htmlUrl.value = `${playSrc}?${queryString}`;

        }
          hideLoading();
      };

    // const pdfWrapper = ref(null);
    const pdfRef = ref(null);
    const page = ref(0);
    const pageCount = ref(0);
    const iframeViewer = ref(null);
    const checkYn =  ()=>{
      if(session.value.itn){
        inyn.value = 'Y';
      }
    };

    const receiveMessage = (event) => {
      try {

        if (event && event.data) {
          let msg = event.data;
          // console.log("event: " + event.data.totalPage);
          // console.log("pageCount: " + msg.pageCount);
          // console.log("actPlaySec: " + msg.actPlaySec);

          //TODO::hideLoading 처리시 message event 발생으로 receiveMessage 반복 호출됨 totalPage 확인 후 return 처리  1533872
          if(!event.data.totalPage)return;

          const params = {
            maxPlaySec: msg.pageCount, //여기서 끊어줄것?
            ...objectParams.value,
          };

          insertLearnProgressResult(params, (res) => {  // PROG_RSLT 로그

            if (isSuccess(res) && res.progRsltSn > 0) {

              progRsltSn.value = res.progRsltSn;
              // if (res.lastPlaySec > 0 && res.lastPlaySec !== pageCount.value) page.value = res.lastPlaySec;

              const params = {
                progRsltSn: progRsltSn.value,
                lrnRsltSn: props.cms.lrnRsltSn,
                maxPlaySec: msg.pageCount,
                lastPlaySec: msg.pageCount,
                ...objectParams.value,
              };

              if (msg.pageCount=== msg.totalPage ) {
                console.log("RESULT!!",msg)
                params.eventCdDcd = eventCdDcds.Ended;
                params.actPlaySec = msg.actPlaySec;
                params.eventNm = 'Ended';
                params.cmptnYn = 'Y';
              } else {
                params.eventCdDcd = eventCdDcds.Timeupdate;
                params.eventNm = 'Timeupdate';
                params.cmptnYn = 'N';
              }
              insertLearnProgressLog(params, () => {  //PROG_LOG 쌓기
                hideLoading();
              });
            }
          });


        }

      } catch (e) {
        // POST MESSAGE FAILED
        console.error('logerror : ', e);
      }
    };




    // handleDocumentRender();

    const isFull = ref(false);


    setTimeout(() => {
      if (isLoading.value) hideLoading();
    }, 1000 * 10);

    onUnmounted(()=>{
      window.removeEventListener('message', receiveMessage);
    });

    onBeforeMount(async () => {
       await makehtmlUrl();
    });
    onMounted(() => {
      checkYn();
      window.addEventListener('message', receiveMessage,false);
    });


    return {
      isMobile: navigationUtils.any(),
      // pdfWrapper,
      pdfRef,
      htmlUrl,
      isLoading,
      page,
      pageCount,
      isFull,
      // handleDocumentRender,
      // goPage,
      // showFullScreen,
      // resizeScreen,
      session,
      iframeViewer,
    };
  },
};
</script>
