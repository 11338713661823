<template>
  <div ref="completeDom" class="coldcall-container" :class="{'is-active' : show }">
    <div class="coldcall" id="coldcall-4" @click.stop="hideComplete">
      <div class="coldcall-contents">
        <img src="@/assets/lxp/mobile/images/hrdcloud/coldcall_image4.png" alt="">
        <div class="contents">
          <h5 class="title">학습완료! 축하드립니다.</h5>
        </div>
      </div>
    </div>
  </div>

</template>
<script>

import {learnCompletePopup} from '@/assets/js/modules/learn/learn-cms-setup';

export default {
  name: 'MobileLearnComplete',
  props: {
    show: Boolean,
    videoInstance: Object,
  },
  emits: ['hideComplete'],
  setup: learnCompletePopup
};
</script>
