<template>
  <div ref="completeDom" class="layer-container" :class="{ 'is-active' : show }" style="background-color: transparent !important;">
    <div class="layer" id="layer-completed" style="padding:0" @click.stop="hideComplete">
      <div class="layer-contents">
        <div class="layer-icon"><img src="@/assets/lxp/images/icon/icon_completed.svg" alt=""></div>
        <strong class="layer-title">학습완료! 축하드립니다.</strong>
      </div>
    </div>
  </div>
</template>
<script>

import {learnCompletePopup} from '@/assets/js/modules/learn/learn-cms-setup';

export default {
  name: 'LearnComplete',
  props: {
    show: Boolean,
    videoInstance: Object,
  },
  emits: ['hideComplete'],
  setup: learnCompletePopup
};
</script>
