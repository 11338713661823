<template>
  <!-- player-image -->
  <div ref="pdfWrapper" class="player-image" :class="{'full-screen': isFull}" style="height: '100%'">
    <vue-pdf-embed
        :source="pdfUrl"
        ref="pdfRef"
        :page="page"
        @rendered="handleDocumentRender"
    />

    <div v-if="isMobile" class="player-controller kb-pdf-controller">
      <div class="controller-series">
        <button class="controller-actions"></button>
      </div>
      <div v-if="pageCount > 1" class="controller-nav">
        <button class="kb-btn-slide-prev" :disabled="page <= 1" @click="goPage(-1)"><i class="icon-prev"></i></button>
        <span class="kb-pagination-current">{{ page }}</span>
        <span class="kb-pagination-total">{{ pageCount }}</span>
        <button class="kb-btn-slide-next" :disabled="page >= pageCount" @click="goPage(1)"><i class="icon-next"></i>
        </button>
      </div>
      <div class="controller-indicator">
        <div class="duration">&nbsp;</div>
        <div class="full-screen">
          <button v-if="!isFull" class="controller-actions" @click="showFullScreen"><i class="icon-full-screen"></i>
          </button>
        </div>
      </div>
    </div>

    <div v-else class="player-controller">
      <div class="controller-row">
        <div class="controller-indicator">
        </div>
        <div v-if="pageCount > 1" class="controller-nav">
          <button class="kb-btn-slide-prev" :disabled="page <= 1" @click="goPage(-1)"><i class="icon-prev"></i></button>
          <span class="kb-pagination-current">{{ page }}</span>
          <span class="kb-pagination-total">{{ pageCount }}</span>
          <button class="kb-btn-slide-next" :disabled="page >= pageCount" @click="goPage(1)"><i class="icon-next"></i>
          </button>
        </div>
        <div class="controller-actions">
          <button v-if="!isFull" class="kb-btn-view" @click="showFullScreen"><i class="icon-view"></i></button>
        </div>
      </div>
    </div>
    <!-- player-nav -->
    <div v-if="!isMobile && pageCount > 1" class="player-nav">
      <button v-if="(page > 1)" class="kb-btn-kb-nav-prev" :disabled="page <= 1" @click="goPage(-1)"><i class="icon-prev"></i>
      </button>
      <div v-else></div>
      <button v-if="!(page >= pageCount)" class="kb-btn-kb-nav-next" :disabled="page >= pageCount" @click="goPage(1)"><i class="icon-next"></i></button>
    </div>

  </div>
  <!-- player-controller -->

</template>

<style>
.player-image.full-screen canvas {
  width: 100% !important;
  height: 100% !important;
}

.player-image {
  height: 100%;
}

.vue-pdf-embed {
  max-height: 100% !important;
  overflow-y: auto;
}
</style>

<script>
import {computed, onMounted, ref} from 'vue';
import VuePdfEmbed from 'vue-pdf-embed';
import {useStore} from 'vuex';
import {getVideoUrl, isSuccess} from '@/assets/js/util';
import {useAlert} from '@/assets/js/modules/common/alert';
import {insertLearnProgressLog, insertLearnProgressResult} from '@/assets/js/modules/learn/learn-common';
import {eventCdDcds} from '@/assets/js/modules/hrd/hrd-common';
import navigationUtils from '@/assets/js/navigationUtils';

export default {
  name: 'LearnPdf',
  components: {VuePdfEmbed},
  props: {
    cms: Object,
    uuid: String,
  },
  setup(props) {

    const store = useStore();
    const {showLoading, hideLoading} = useAlert();
    const session = computed(() => store.state.auth.session);
    const isInit = ref(false);
    const isLoading = ref(true);
    const progRsltSn = ref(0);

    showLoading();

    const pdfUrl = computed(() => {
      return getVideoUrl(session.value.itn, props.cms);
    });

    const pdfWrapper = ref(null);
    const pdfRef = ref(null);
    const page = ref(1);
    const pageCount = ref(0);

    const objectParams = computed(() => {
      return {
        distCrseSn: props.cms.distCrseSn,
        distChapSn: props.cms.distChapSn,
        lrnObjDtlDistSn: props.cms.lrnObjDtlDistSn,
        objSn: props.cms.objSn,
        division: 'pdf',
      };
    });
    const handleDocumentRender = () => {
      isLoading.value = false;

      // 처음 init
      if (!isInit.value) {
        pageCount.value = pdfRef.value.pageCount;
        isInit.value = true;
        const params = {
          maxPlaySec: pageCount.value,
          ...objectParams.value,
        };
        if (pageCount.value > 0) {
          insertLearnProgressResult(params, (res) => {
            if (isSuccess(res) && res.progRsltSn > 0) {
              progRsltSn.value = res.progRsltSn;
              if (res.lastPlaySec > 0 && res.lastPlaySec !== pageCount.value) page.value = res.lastPlaySec;

              const params = {
                progRsltSn: progRsltSn.value,
                lrnRsltSn: props.cms.lrnRsltSn,
                lastPlaySec: page.value,
                ...objectParams.value,
              };

              if (pageCount.value === page.value) {
                params.eventCdDcd = eventCdDcds.Ended;
                params.eventNm = 'Ended';
                params.cmptnYn = 'Y';
              } else {
                params.eventCdDcd = eventCdDcds.Timeupdate;
                params.eventNm = 'Timeupdate';
                params.cmptnYn = 'N';
              }
              insertLearnProgressLog(params, () => {
                hideLoading();
              });
            }
          });
        }

      }
    };

    const goPage = (unit) => {
      showLoading();
      const changePage = page.value + unit;
      const params = {
        progRsltSn: progRsltSn.value,
        lrnRsltSn: props.cms.lrnRsltSn,
        lastPlaySec: changePage,
        ...objectParams.value,
      };
      if (pageCount.value === changePage) {
        params.eventCdDcd = eventCdDcds.Ended;
        params.eventNm = 'Ended';
        params.cmptnYn = 'Y';
      } else {
        params.eventCdDcd = eventCdDcds.Timeupdate;
        params.eventNm = 'Timeupdate';
        params.cmptnYn = 'N';
      }
      insertLearnProgressLog(params, () => {
        page.value = changePage;
        hideLoading();
      });
    };

    const isFull = ref(false);
    const showFullScreen = () => {
      if (pdfWrapper.value.requestFullscreen) pdfWrapper.value.requestFullscreen();
      else if (pdfWrapper.value.webkitRequestFullscreen) pdfWrapper.value.webkitRequestFullscreen();
      else if (pdfWrapper.value.mozRequestFullScreen) pdfWrapper.value.mozRequestFullScreen();
      else if (pdfWrapper.value.msRequestFullscreen) pdfWrapper.value.msRequestFullscreen();
    };

    // const escEvent = e => {
    //   if (e.key === 'Escape') {
    //     isFull.value = false
    //   }
    // }

    // document.addEventListener('keyup', escEvent);

    const resizeScreen = () => {
      isFull.value = !isFull.value;
      if (!isFull.value) {
        pdfRef.value.render();
      }
    };

    setTimeout(() => {
      if (isLoading.value) hideLoading();
    }, 1000 * 10);



    onMounted(() => {
      pdfWrapper.value.addEventListener('fullscreenchange', resizeScreen);
    });

    // onUnmounted(() => {
      // pdfWrapper.value.removeEventListener('fullscreenchange');
      // document.removeEventListener('keyup', escEvent);
    // })

    return {
      isMobile: navigationUtils.any(),
      pdfWrapper,
      pdfRef,
      pdfUrl,
      isLoading,
      page,
      pageCount,
      isFull,
      handleDocumentRender,
      goPage,
      showFullScreen,
      resizeScreen,
    };
  },
};
</script>
