<template>
<!--  <button class="kb-btn kb-btn-primary ms-3" @click="goNativePlayer"><span class="text">네이티브 플레이어</span></button>-->
  <div
      ref="layoutDom"
      class="player-video kb-video-wrapper"
      :class="{'kb-size-100': !isFull, 'kb-size-100-padding-0': !isMobile}"
  >
    <video class="vjs-matrix video-js vjs-default-skin vjs-big-play-centered vjs-fullscreen"
           ref="videoDom"
           oncontextmenu="return false;"
           playsinline
    >
    </video>

    <button v-show="isMobile || isiOS" ref="fullscreenBtn" class="vjs-fullscreen-control vjs-control vjs-button" type="button" title="Fullscreen" aria-disabled="false" @click="toggleFullScreen">
      <span class="vjs-icon-placeholder" aria-hidden="true"></span>
      <span class="vjs-control-text" aria-live="polite">Fullscreen</span>
    </button>

    <template v-if="coldCall">
      <MobileLearnColdCall
          v-if="isMobile"
          :layout-dom="layoutDom"
          :video-instance="videoInstance"
          :meta="meta"
          :object-params="objectParams"
          :uuid="uuid"
          :prog-rslt-sn="progRsltSn"
          :lrn-rslt-sn="cms.lrnRsltSn"
          @abortLearn="abortLearn"
          @completeLearn="completeLearn"
      />
      <LearnColdCall
          v-else
          :layout-dom="layoutDom"
          :video-instance="videoInstance"
          :meta="meta"
          :object-params="objectParams"
          :uuid="uuid"
          :prog-rslt-sn="progRsltSn"
          :lrn-rslt-sn="cms.lrnRsltSn"
          @abortLearn="abortLearn"
          @completeLearn="completeLearn"
      />
    </template>
    <LearnContinue
        v-if="continueMeta.show"
        :useColdCall="useColdCall"
        @continueLearn="continueLearn"
        @resetLearn="resetLearn"
    />

    <template v-if="readies.complete">
      <MobileLearnComplete
          v-if="isMobile"
          :show="shows.complete"
          :video-instance="videoInstance"
          @hideComplete="hideComplete"
      />
      <LearnComplete
          v-else
          :show="shows.complete"
          :video-instance="videoInstance"
          @hideComplete="hideComplete"
      />
    </template>

  </div>
</template>
<style scoped>
.lxp-mobile-layout .player .player-video video {width:100%;height:100%;object-fit:contain;}
</style>
<script>
import videojs from 'video.js';
import {computed, onBeforeUnmount, onMounted, reactive, ref} from 'vue';
import {clearTargetInterval, clearTargetTimeout, getType, getVideoUrl, isSuccess} from '@/assets/js/util';
import {useStore} from 'vuex';
import {eventCdDcds} from '@/assets/js/modules/hrd/hrd-common';
import {useAlert} from '@/assets/js/modules/common/alert';
import {insertLearnProgressLog} from '@/assets/js/modules/learn/learn-common';
import {ACT_INSERT_LEARN_PROGRESS_RESULTS} from '@/store/modules/learn/learn';
import LearnColdCall from '@/components/learn/window/LearnColdCall';
import MobileLearnColdCall from '@/components/learn/mobile/window/MobileLearnColdCall';
import navigationUtils, {isInAppAnd, isInAppIOS} from '@/assets/js/navigationUtils';
import LearnContinue from '@/components/learn/window/LearnContinue';
import LearnComplete from '@/components/learn/window/LearnComplete';
import MobileLearnComplete from '@/components/learn/mobile/window/MobileLearnComplete';
import {getThumbImg} from '@/assets/js/modules/baner/common-baner';
import {learnCallNative} from '@/assets/js/modules/learn/learn-native-common';
import {getColdCallTime} from '@/assets/js/modules/learn/learn-cms-setup';
import {ACT_RENEW_AUTH} from '@/store/modules/auth/auth';

export default {
  name: 'LearnVideo',
  components: {MobileLearnComplete, LearnComplete, LearnContinue, MobileLearnColdCall, LearnColdCall},
  props: {
    item: Object,
    cms: Object,
    memoryLimit: {
      type: Number,
      default: 0
    },
    useColdCall: Boolean,
    uuid: String
  },
  emits: ['toggleFull'],
  setup(props, {emit}) {
    const store = useStore();
    const {showLoading, hideLoading} = useAlert();
    const isMobile = computed(() => store.state.auth.isMobile);
    const session = computed(() => store.state.auth.session);

    // const isInApp = computed(() => session.value.lrnerId === 'S017352')
    const isInApp = computed(() => isInAppAnd || isInAppIOS);
    const isPlay = ref(false);
    const completeDom = ref(null);
    const layoutDom = ref(null);
    const videoDom = ref(null);
    const videoInstance = ref(null);
    const hideProgress = ref(true);
    const isLoaded = ref(false);
    const progRsltSn = ref(0);
    const fullscreenBtn = ref(null);
    const isiOS = navigationUtils.isApplePortable();

    const continueMeta = reactive({
      show: false,
      exposure: false,
      lastPlaySec: 0,
    });

    const readies = reactive({
      complete: false
    });

    const shows = reactive({
      complete: false
    });

    showLoading();

    const meta = reactive({
      duration: 0,
      lastPlaySec: 0,
      currentTime: 0,
      logSec: 0
    });

    const coldCall = ref(false)

    const secondToFormat = (currentTime) => {
      const sec = parseInt(currentTime, 10); // don't forget the second param
      const hour   = Math.floor(sec / 3600);
      const minute = Math.floor((sec - (hour * 3600)) / 60);
      const seconds = sec - (hour * 3600) - (minute * 60);
      if(hour > 0){
        return `0${hour}`.slice(-2) + ':' + `0${minute}`.slice(-2) + `0${seconds}`.slice(-2);
      }
      return `0${minute}`.slice(-2) + ':' + `0${seconds}`.slice(-2);
    }

    const objectParams = computed(() => {
      return {
        distCrseSn: props.cms.distCrseSn,
        distChapSn: props.cms.distChapSn,
        lrnObjDtlDistSn: props.cms.lrnObjDtlDistSn,
        objSn: props.cms.objSn,
        division: 'vod'
      }
    });

    const lrnAbortYn = ref('N');


    const hideComplete = () => {
      shows.complete = false;
    }

    const playWithContinueHide = () => {
      if (props.useColdCall) coldCall.value = true;
      // videoInstance.value.play();
      continueMeta.show = false;
    }

    const continueLearn = () => {
      meta.logSec = continueMeta.lastPlaySec;
      meta.lastPlaySec = continueMeta.lastPlaySec;
      videoInstance.value.currentTime(continueMeta.lastPlaySec);
      playWithContinueHide();
    }

    const resetLearn = () => {
      playWithContinueHide();
    }

    const abortLearn = () => {
      lrnAbortYn.value = 'Y';
    }

    const completeLearn = () => {
      shows.complete = true;
      setTimeout(() => {
        shows.complete = false;
      }, 10 * 1000)
    }

    const checkComplete = res => {
      if (res === 1) completeLearn();
    }

    const checkNumberWithErrorRange = (target1, target2, rangeNo) => {
      const add = target1 + rangeNo;
      const minus = target1 - rangeNo;
      return minus <= target2 &&  target2 <= add;
    }

    const isFull = ref(false);

    const toggleFullScreen = () => {
      if (videoInstance.value !== null) {
        if (!isFull.value) {
          videoInstance.value.enterFullWindow();
        } else {
          videoInstance.value.exitFullWindow();
        }
      }
    };

    // streamUrl, thumbUrl, contentPlayTime

    // const videoMeta = reactive({
    //   src: getVideoUrl(session.value.itn, props.cms),
    // })

    // progressMeta => { progSesnKey, distCrseSn, distChapSn, lrnObjDtlDistSn, objSn, objNm, progRsltSn, lrnRsltSn, division }

    // {
    //   distCrseSn: props.cms.distCrseSn,
    //       distChapSn: props.cms.distChapSn,
    //     lrnObjDtlDistSn: props.cms.lrnObjDtlDistSn,
    //     objSn: props.cms.cttMstSn,
    //     division: 'vod'
    // }

    const goNativePlayer = () => {
      const coldCallTime = props.useColdCall ? getColdCallTime(0, meta.duration, 0) : 0;

      const videoMeta = {
        streamUrl : getVideoUrl(session.value.itn, props.cms),
        thumbUrl: getThumbImg(props.cms.thumb),
        contentPlayTime: meta.duration
      }

      const progressMeta = {
        progSesnKey: props.uuid,
        ...objectParams.value,
        objNm: props.item.objNm,
        progRsltSn: progRsltSn.value,
        lrnRsltSn: props.cms.lrnRsltSn
      }

      learnCallNative(progressMeta, videoMeta, coldCallTime)
    }

    // 10분에 한번 세션 갱신
    let authRenewInterval = setInterval(() => {
      if (isPlay.value) store.dispatch(`auth/${ACT_RENEW_AUTH}`).then(() => {});
    }, 1000 * 60 * 5);

    const videoDebounce = reactive({
      play: 0,
      pause: 0
    });

    onMounted(() => {
      const options = isMobile.value ? {
        fluid: false,
        preload: 'metadata',
        autoplay: false,
        crossOrigin: 'anonymous',
        language: 'ko',
        width: window.innerWidth,
        height: 360,
        controls: true,
        controlBar: {
          subsCapsButton: false,
          audioTrackButton: false,
          progressControl: true,
          fullscreenToggle: !isiOS
        },
        playbackRate: [1, 1.2],
        sources: [{
          src: getVideoUrl(session.value.itn, props.cms),
          type: getType(props.cms.etnUrl),
        }],
      } : {
        fluid: true,
        preload: 'metadata',
        autoplay: false,
        crossOrigin: 'anonymous',
        language: 'ko',
        controls: true,
        controlBar: {
          subsCapsButton: false,
          audioTrackButton: false,
          progressControl: true,
          fullscreenToggle: true
        },
        playbackRate: [1, 1.2],
        sources: [{
          src: getVideoUrl(session.value.itn, props.cms),
          type: getType(props.cms.etnUrl),
        }],
      }

      videojs(videoDom.value, options, function() {
        videoInstance.value = this;
        // 학습완료 적용
        if (isMobile.value || isiOS) {
          videoInstance.value.el().appendChild(fullscreenBtn.value);
          videoInstance.value.on('exitFullWindow', () => {
            isFull.value = false;
            emit('toggleFull', isFull.value);
          });

          videoInstance.value.on('enterFullWindow', () => {
            isFull.value = true;
            emit('toggleFull', isFull.value);
          });

          const controlsEls = videoInstance.value.el().getElementsByClassName('vjs-control-bar');
          if (controlsEls.length > 0) {
            controlsEls[0].append(fullscreenBtn.value)
          }
        }

        this.on('loadedmetadata', async () => {
          readies.complete = true;

          meta.duration = parseInt(this.duration());
          const params = {
            maxPlaySec: meta.duration,
            ...objectParams.value,
            coldCallUseYn: props.useColdCall ? 'Y' : 'N'
          }

          const res = await store.dispatch(`learn/${ACT_INSERT_LEARN_PROGRESS_RESULTS}`, params);
          if(isSuccess(res) && res.progRsltSn > 0) {
            progRsltSn.value = res.progRsltSn;
            // 여기서 콜드콜 현재 조회
            // if(res.lastPlaySec > 0 && res.lastPlaySec !== parseInt(meta.duration)){
            if(res.lastPlaySec > 0 && !checkNumberWithErrorRange(res.lastPlaySec, meta.duration, 1)){

              if(props.memoryLimit > 0){
                if(new Date().getTime() - (res.lastDt || 0) < (props.memoryLimit * 1000 * 60 * 60 * 24)){
                  continueMeta.exposure = true;
                  continueMeta.lastPlaySec = res.lastPlaySec;
                } else {
                    if (props.useColdCall) coldCall.value = true;
                }
              }else{
                // continueMeta.lastPlaySec = res.lastPlaySec;
                // // meta.logSec = res.lastPlaySec;
                // // 옵션처리...
                // this.currentTime(res.lastPlaySec);
                if (props.useColdCall) coldCall.value = true;
              }
            } else {
              if (props.useColdCall) coldCall.value = true;
            }
            // 콜드콜 사용하는 경우

            isLoaded.value = true;
            hideLoading();
          } else {
            hideLoading();
          }
        });

        this.on('error', () => {
          hideLoading();
        });

        this.on('play', () => {
          isPlay.value = true;
          if (continueMeta.exposure) {
            this.pause();
            continueMeta.show = true;
            continueMeta.exposure = false;
          } else {
            const playbackRate = this.playbackRate();
            const ct = parseInt(this.currentTime());
            const playParams = {
              ...objectParams.value,
              progRsltSn: progRsltSn.value,
              progSesnKey: props.uuid,
              lrnRsltSn: props.cms.lrnRsltSn,
              lastPlaySec: ct,
              eventCdDcd: eventCdDcds.Play,
              eventNm: 'Play',
              cmptnYn: 'N',
              playSpeed: playbackRate,
              lrnAbortYn: lrnAbortYn.value
            };

            clearTargetTimeout(videoDebounce.play);

            videoDebounce.play = setTimeout(() => {
              insertLearnProgressLog(playParams);
            }, 500);

          }
        });

        this.on('pause', () => {
          isPlay.value = false;
          if (!continueMeta.exposure) {
            const playbackRate = this.playbackRate();
            const ct = parseInt(this.currentTime());

            const pauseParams ={
              ...objectParams.value,
              progRsltSn: progRsltSn.value,
              progSesnKey: props.uuid,
              lrnRsltSn: props.cms.lrnRsltSn,
              lastPlaySec: ct,
              eventCdDcd: eventCdDcds.Pause,
              eventNm: 'Pause',
              cmptnYn: 'N',
              playSpeed: playbackRate,
              lrnAbortYn: lrnAbortYn.value
            };

            clearTargetTimeout(videoDebounce.pause);
            videoDebounce.pause = setTimeout(() => {
              insertLearnProgressLog(pauseParams);
            }, 500);
          }

        });


        this.on('seeked', function() {
          meta.logSec = this.currentTime();
        });

        // let count = 0;
        this.on('timeupdate', () => {
          meta.currentTime = this.currentTime();

          if (meta.duration > 0) {
            // 현재 시간
            const ct = parseInt(this.currentTime());
            // 매 30초마다 로그 저장하겠다
            if (ct - meta.logSec >= 30 && ct - meta.logSec < 31) {
            // if (ct - meta.logSec >= 10 && ct - meta.logSec < 11) {
              meta.logSec = ct;
              // 해당 변수가 있어야 진도율이 갱신되도록 적용
              if(progRsltSn.value > 0){
                const playbackRate = this.playbackRate();
                meta.duration = this.duration();
                const timeupdateParams = {
                  ...objectParams.value,
                  progRsltSn: progRsltSn.value,
                  progSesnKey: props.uuid,
                  lrnRsltSn: props.cms.lrnRsltSn,
                  lastPlaySec: ct,
                  eventCdDcd: eventCdDcds.Timeupdate,
                  eventNm: 'Timeupdate',
                  cmptnYn: 'N',
                  playSpeed: playbackRate,
                  lrnAbortYn: lrnAbortYn.value
                };
                insertLearnProgressLog(timeupdateParams);
              }
            }
          }
        });

        this.on('ended', () => {
          const ct = parseInt(this.currentTime());
          if(progRsltSn.value > 0){
            const playbackRate = this.playbackRate();
            meta.duration = this.duration();
            const params = Object.assign(objectParams.value, {
              progRsltSn: progRsltSn.value,
              progSesnKey: props.uuid,
              lrnRsltSn: props.cms.lrnRsltSn,
              lastPlaySec: ct,
              eventCdDcd: eventCdDcds.Ended,
              eventNm: 'Ended',
              cmptnYn: 'Y',
              playSpeed: playbackRate,
              lrnAbortYn: lrnAbortYn.value
            });
            insertLearnProgressLog(params, checkComplete);
          }
        });
      });
    });


    onBeforeUnmount(() => {
      if (videoInstance.value) videoInstance.value.dispose();
      clearTargetInterval(authRenewInterval);
    });


    return {
      isInApp,
      isMobile,
      isiOS,
      isFull,
      continueMeta,
      fullscreenBtn,
      layoutDom,
      videoDom,
      videoInstance,
      progRsltSn,
      coldCall,
      meta,
      hideProgress,
      objectParams,
      completeDom,
      readies,
      shows,
      hideComplete,
      toggleFullScreen,
      secondToFormat,
      abortLearn,
      continueLearn,
      resetLearn,
      completeLearn,
      goNativePlayer
    };
  },
};
</script>
