<template>
  <div v-if="isMobile" class="coldcall-container is-active">
    <div class="coldcall" id="coldcall-2">
      <div class="coldcall-contents">
        <img src="@/assets/lxp/mobile/images/hrdcloud/coldcall_image3.png" alt="">
        <div class="contents"><h5 class="title">이어보시겠습니까?</h5>
          <p v-if="useColdCall" class="text">이어보기 위치에 따라 <span class="text-red">학습확인(Cold Call) 미호출</span>로 인해<br><span class="text-red">학습완료가 미인정</span>될 수 있습니다.</p>
          <p class="text-sm text-muted">※ 정상적인 학습완료를 위해서는 '다시학습' 을 권장드립니다.</p></div>
      </div>
      <div class="coldcall-actions">
        <button class="kb-btn-action"><span class="text text-gold" @click="resetLearn">다시학습</span></button>
        <button class="kb-btn-action"><span class="text" @click="continueLearn">계속학습</span></button>
      </div>
    </div>
  </div>
  <div v-else class="layer-container is-active" style="background-color: #231F20">
    <div class="layer" id="layer-coldcall" style="padding: 0 !important;">
      <div class="layer-contents">
        <div class="layer-icon"><img src="@/assets/lxp/images/icon/icon_coldcall_3.svg" alt="" /></div>
        <strong class="layer-title">이어보시겠습니까?</strong>
        <div class="layer-content">
          <p v-if="useColdCall" class="text">이어보기 위치에 따라 <span class="text-red">학습확인(Cold Call) 미호출</span>로 인해<br> <span class="text-red">학습완료가 미인정</span>될 수 있습니다.</p>
          <p class="text-sm text-muted">※ 정상적인 학습완료를 위해서는 '다시학습' 을 권장드립니다.</p>
        </div>
      </div>
      <div class="layer-actions">
        <button class="kb-btn-layer" @click="resetLearn">
          <strong class="text text-gold">다시학습</strong>
        </button>
        <button class="kb-btn-layer" @click="continueLearn">
          <strong class="text">계속학습</strong>
        </button>
      </div>
    </div>
  </div>


  <!-- [end::layer-container] -->
</template>
<script>

import {computed} from 'vue';
import {useStore} from 'vuex';

export default {
  name: 'LearnContinue',
  props: {
    useColdCall: Boolean,
  },
  emits: ['continueLearn', 'resetLearn'],
  setup(props, {emit}) {
    const store = useStore();
    const isMobile = computed(() => store.state.auth.isMobile);

    const continueLearn = () => {
      emit('continueLearn');
    }
    const resetLearn = () => {
      emit('resetLearn');
    }

    return {
      isMobile,
      continueLearn,
      resetLearn
    }

  }
};
</script>
