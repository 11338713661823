import {computed, onMounted, onUnmounted, reactive, ref} from 'vue';
import {clearTargetTimeout, getRandom} from '@/assets/js/util';
import {useStore} from 'vuex';
import {eventCdDcds} from '@/assets/js/modules/hrd/hrd-common';
import {
  ACT_INSERT_LEARN_PROGRESS_LOG_COLD_CALL,
  ACT_UPDATE_LEARN_PROGRESS_LOG_COLD_CALL,
} from '@/store/modules/learn/learn';


export const getColdCallTime = (currTime, duration, coldCallRound) => {
  // 남은 시간
  const remainTime = duration - currTime;

  // 영상 재생 남은 시간이 1분 이상의 영상만 콜드콜을 노출한다
  if (remainTime > 60) {
    // 영상 재생 남은 시간이 3분10초 이하의 영상들
    if (remainTime <= 190) {
      if (coldCallRound === 0) {
        // 49초 후에 노출
        return (currTime + 49);
        // return (currTime + 10);

      } else if (coldCallRound === 1) {
        // 3분 10초 이하의 영상의 두번째 콜드콜은 첫번째 콜드콜 이후 "9초 이후"에서 종료시간 "20초 전" 이하의 "랜덤시간"으로 처리한다.
        const startTime = (currTime + 4);
        const endTime = duration - 20;
        // 종료시간전까지 20초의 시간이 남아 있다면
        return endTime >= startTime ? getRandom(startTime, endTime) : 0;
      }
    }
    // 영상 재생 남은 시간이 3분10초 초과의 영상들
    else {
      // 남은 시간이 10분 이하인 경우 영상 종료 1분 전까지만 콜드콜 노출
      if (remainTime <= 600) {
        return getRandom((duration - 120), (duration - 60));
        // 남은 시간이 120초 이상인 경우 그냥 랜덤 처리
      }
      // 10분 이상 초과의 경우 남은 영상 시간이 1/2 도달했을 때분터 영상 종료 1분전까지만 콜드콜 노출
      else {
        return getRandom(currTime + (remainTime/2), (duration - 60));
        // return 20;
      }
    }
  }
  return 0;
}

export const learnColdCallSetup = (props, {emit}) => {
  // const isMobile = computed(() => store.state.auth.isMobile);
  // const moveDom1 = ref(null);
  // const moveDom2 = ref(null);
  const store = useStore();

  const wrapperDom1 = ref(null);
  const wrapperDom2 = ref(null);
  const coldCallDom = ref(null);

  const params = reactive( {
    progLogSn: 0,
    coldCallRound: 0,
    responded: false,
    coldCallTime: 0,
  });

  const position = reactive({
    top: 0,
    left: 0
  })

  const coldCallTimeout = ref(null);
  const responseTimeout = ref(null);

  const toggle = reactive({
    coldCall: false,
    miss: false,
  });

  // 콜드콜 노출 위치 설정
  const setPosition = (layoutDom, coldCallDom) => {
    if (layoutDom && coldCallDom) {

      const pOff = layoutDom.getBoundingClientRect(), cOff =  coldCallDom.getBoundingClientRect();
      const qHeight = window.innerHeight < pOff.height ? window.innerHeight : pOff.height;
      const qWidth = window.innerWidth < pOff.width ? window.innerWidth : pOff.width;

      const x1 = 0; // 플레이어 레이어 left 시작 위치
      const x2 = qWidth - cOff.width; // 콜드콜 레이어 width만큼 플레이어 밖으로 안나가는 최대 위치
      const y1 = 0; // 플레이어 레이어 top 시작 위치
      // const y2 = pOff.height - cOff.height; // 콜드콜 레이어 heigth만큼 플레이어 밖으로 안나가는 최대 위치
      const y2 = qHeight - cOff.height; // 콜드콜 레이어 heigth만큼 플레이어 밖으로 안나가는 최대 위치
      const randomLeft = getRandom(x1, x2);
      const randomTop = getRandom(y1,y2);
      position.left = randomLeft + cOff.width > pOff.width ? x2 : randomLeft;
      position.top = randomTop + cOff.height > qHeight ? y2 : randomTop;

    } else {
      position.left = 0;
      position.top = 0;
    }
  };

  const getEventNm = (eventCdDcd) => {
    if (eventCdDcds.Coldcall === eventCdDcd) {
      return '콜드콜 전송';
    } else if (eventCdDcds.ColdcallStop === eventCdDcd) {
      return '중단알림 전송';
    }
    return '';
  }

  const insertColdCall = (eventCdDcd, currentTime, playbackRate) => {
    store.dispatch(`learn/${ACT_INSERT_LEARN_PROGRESS_LOG_COLD_CALL}`, {
      progSesnKey: props.uuid,
      lastPlaySec: currentTime,
      eventCdDcd,
      eventNm: getEventNm(eventCdDcd),
      cmptnYn: 'N',
      playSpeed: playbackRate,
      ...props.objectParams,
      lrnAbortYn: 'N',
      coldCallRound: (params.coldCallRound + 1)
    }).then(res => {
      params.progLogSn = (res || 0);
    });
  }

  const updateColdCall = (coldCallDivision, callback) => {
    if (params.progLogSn > 0) {
      store.dispatch(`learn/${ACT_UPDATE_LEARN_PROGRESS_LOG_COLD_CALL}`, {
        progLogSn: params.progLogSn,
        params: {
          progRsltSn: props.progRsltSn,
          lrnRsltSn: props.lrnRsltSn,
          ...props.objectParams,
          coldCallDivision,
        }
      }).then(res => {
        if (callback instanceof Function) callback(res);
      });
    }
  }


  const showColdCall = (currentTime, playbackRate) => {
    if (params.responded) return;
    // props.videoInstance.pause();
    insertColdCall(eventCdDcds.Coldcall, currentTime, playbackRate);
    setPosition(props.layoutDom, coldCallDom.value);

    toggle.coldCall = true;
    // 아무 반응이 없을 경우 15초 뒤에 처리
    coldCallTimeout.value = setTimeout(() => {
      toggle.coldCall = false;
      params.coldCallRound++;
      updateColdCall('e');

      // 최초는 그냥 재갱신만...
      if (params.coldCallRound === 1) {
        // 닫침 처리
        if (props.videoInstance) params.coldCallTime = getColdCallTime(props.videoInstance.currentTime(), props.meta.duration, params.coldCallRound);

        // props.videoInstance.play();
      } else {
        props.videoInstance.pause();
        // 두번 이상은 miss 처리
        insertColdCall(eventCdDcds.ColdcallStop, currentTime, playbackRate);
        toggle.miss = true;
      }
      // 다시 콜드콜 처리
    }, 15 * 1000);
    // }, 300 * 1000);
  }

  const responseColdCall = () => {
    if (params.responded) {
      toggle.coldCall = false;
      return;
    }

    clearTargetTimeout(coldCallTimeout.value);
    // 닫침, 반응 처리
    updateColdCall('er', res => {if (res === 1) emit('completeLearn')});
    params.responded = true;
    // 여기서 처리...
    responseTimeout.value = setTimeout(() => {toggle.coldCall = false;}, 3000);
    // 콜드콜 반응 처리
  }

  const resetColdCall = () => {
    // miss 닫침 처리
    updateColdCall('e');
    props.videoInstance.currentTime(0);
    toggle.miss = false;
    params.progLogSn = 0;
    params.responded = false;
    params.coldCallRound = 0;
    // 콜드콜 타임 재갱신
    params.coldCallTime = getColdCallTime(0, props.meta.duration, params.coldCallRound);
    props.videoInstance.play();
  };

  // 콜드콜 중단
  const continueWatching = () => {
    // 놓침 창 종료 시간 갱신 및 학습 종료 처리
    updateColdCall('ea');
    params.progLogSn = 0;
    params.coldCallTime = 0;
    toggle.miss = false;
    // 학습 중단처리
    emit('abortLearn');
    props.videoInstance.play();
  };

  // 처음 콜드콜 정보를 설정한다.
  params.coldCallTime = getColdCallTime((props.meta.lastPlaySec || 0), props.meta.duration, params.coldCallRound);

  if(props.videoInstance) {
    props.videoInstance.on('seeked', function() {
      // 콜드콜 위치 재갱신
      params.coldCallTime = getColdCallTime(props.videoInstance.currentTime(), props.meta.duration, params.coldCallRound);
    });

    // 콜드콜 이벤트 적용
    props.videoInstance.on('timeupdate', function() {
      const currentTime = this.currentTime();
      if(!toggle.coldCall && !toggle.miss && !params.responded && params.coldCallRound < 2 && params.coldCallTime > 0) {
        if(currentTime < params.coldCallTime && (currentTime + 1) >= params.coldCallTime) {
          const playbackRate = this.playbackRate();
          showColdCall(currentTime, playbackRate);
        }
      }
    });

    props.videoInstance.on('play', function() {
      // 콜드콜 또는 miss 알럿이 노출된 플레이어는 영상을 재생할 수 없다.
      if (toggle.miss) {
        this.pause();
      }
    });
  }

  onMounted(() => {
    // 전체화면 이슈로 mv 시킴
    if (props.videoInstance) {
      props.videoInstance.el().appendChild(wrapperDom1.value);
      props.videoInstance.el().appendChild(wrapperDom2.value);
    }
  });

  onUnmounted(() => {
    clearTargetTimeout(coldCallTimeout.value);
  });

  return {
    wrapperDom1,
    wrapperDom2,
    coldCallDom,
    params,
    toggle,
    position,
    resetColdCall,
    continueWatching,
    responseColdCall
  }
}



export const learnCompletePopup = (props, {emit}) => {
  const store = useStore();
  const isMobile = computed(() => store.state.auth.isMobile);

  const completeDom = ref(null);

  const hideComplete = () => {
    emit('hideComplete');
  }

  onMounted(() => {
    if (props.videoInstance) {
      props.videoInstance.el().appendChild(completeDom.value);
    }
  });

  return {
    isMobile,
    completeDom,
    hideComplete,
  }

}
