<template>
  <div ref="wrapperDom1" class="coldcall-container" :class="{ 'is-active' : toggle.coldCall }">
    <div ref="coldCallDom"
         class="coldcall"
         :class="{'kb-success-layer': params.responded}"
         @click.stop="responseColdCall" :style="{ top:`${position.top}px !important`, left:`${position.left}px !important` }">
      <a class="coldcall-contents">
        <img v-show="params.responded" src="@/assets/lxp/mobile/images/hrdcloud/icon_coldcall_completed.png" alt="">
        <img v-show="!params.responded" src="@/assets/lxp/mobile/images/hrdcloud/coldcall_image.png" alt="">
        <div v-if="params.responded">
          <h5>클릭 성공!</h5>
          <p>학습을 계속 진행해주세요.</p>
        </div>
        <div v-else>
          <h5>잠깐!</h5>
          <p>학습중이라면 클릭해주세요.</p>
        </div>
      </a>
    </div>
  </div>

  <div ref="wrapperDom2" class="coldcall-container" :class="{ 'is-active' : toggle.miss }">
    <div class="coldcall" id="coldcall-2">
      <div class="coldcall-contents">
        <img src="@/assets/lxp/mobile/images/hrdcloud/coldcall_image2.png">
        <div class="contents">
          <h5 class="title">학습확인(Cold Call) 미응답 안내</h5>
          <p class="text text-sm"><span class="text-red">학습확인(Cold Call) 미응답</span>으로<br><span class="text-red">학습완료가 인정되지 않습니다.</span></p>
        </div>
      </div>
      <div class="coldcall-actions">
        <button class="kb-btn-action" @click="resetColdCall"><span class="text text-gold" >다시학습</span></button>
        <button class="kb-btn-action" @click="continueWatching"><span class="text">계속학습</span></button>
      </div>
    </div>
  </div>

</template>

<script>
import {learnColdCallSetup} from '@/assets/js/modules/learn/learn-cms-setup';

export default {
  name: 'MobileLearnColdCall',
  props: {
    layoutDom: Object,
    videoInstance: Object,
    meta: Object,
    objectParams: Object,
    uuid: String,
    progRsltSn: Number,
    lrnRsltSn: Number
  },
  emits: ['abortLearn', 'completeLearn'],
  setup: learnColdCallSetup
};
</script>
