<template>
  <!-- [begin::layer-container] -->
  <div
      ref="wrapperDom1"
       class="layer-container" style="background-color:unset;"
       :class="{ 'is-active' : toggle.coldCall }"
  >
    <div ref="coldCallDom" class="layer kb-cold-call-boarder"
         :class="{'kb-success-layer': params.responded, 'kb-mouse-cursor': !params.responded, 'kb-blur-layer': !params.responded}"
         @click.stop="responseColdCall"
         :style="{top:`${position.top}px !important`, left:`${position.left}px !important`}">
      <div class="layer-contents" >
        <div class="layer-icon">
          <img v-show="params.responded" src="@/assets/lxp/images/icon/icon_coldcall_completed.svg" alt="" />
          <img v-show="!params.responded" src="@/assets/lxp/images/icon/icon_coldcall.svg" alt="" />
        </div>
        <template v-if="params.responded">
          <strong class="layer-title">클릭 성공!</strong>
          <div class="layer-content">
            <p class="text">학습을 계속 진행해주세요.</p>
          </div>
        </template>
        <template v-else>
          <strong class="layer-title">잠깐!</strong>
          <div class="layer-content">
            <p class="text">학습중이라면 클릭해주세요.</p>
          </div>
        </template>
      </div>
    </div>
  </div>
  <!-- [end::layer-container] -->
  <!-- [begin::layer-container] -->
  <div ref="wrapperDom2" class="layer-container" style="background-color: #231F20" :class="{ 'is-active' : toggle.miss }">
    <div class="layer" id="layer-coldcall" style="padding: 0 !important;">
      <div class="layer-contents">
        <div class="layer-icon"><img src="@/assets/lxp/images/icon/icon_coldcall_2.svg" alt="" /></div>
        <strong class="layer-title">학습확인(Cold Call) 미응답 안내</strong>
        <div class="layer-content">
<!--          <p class="text">현재학습중인 동영상은 ‘학습확인(Cold Call)’ 미클릭으로 인해 <br>학습완료 및 마일리지가 인정되지 않습니다.</p>-->
          <p class="text text-sm"><span class="text-red">학습확인(Cold Call) 미응답</span>으로<br><span class="text-red">학습완료가 인정되지 않습니다.</span></p>
        </div>
      </div>
      <div class="layer-actions">
        <button class="kb-btn-layer" @click="resetColdCall">
          <strong class="text text-gold">다시학습</strong>
        </button>
        <button class="kb-btn-layer" @click="continueWatching">
          <strong class="text">계속학습</strong>
        </button>
      </div>
    </div>
  </div>
  <!-- [end::layer-container] -->
</template>

<script>
import {learnColdCallSetup} from '@/assets/js/modules/learn/learn-cms-setup';

export default {
  name: 'LearnColdCall',
  props: {
    layoutDom: Object,
    videoInstance: Object,
    meta: Object,
    objectParams: Object,
    uuid: String,
    progRsltSn: Number,
    lrnRsltSn: Number
  },
  emits: ['abortLearn', 'completeLearn'],
  setup: learnColdCallSetup
};
</script>
