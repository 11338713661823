import store from '@/store';
import {ACT_INSERT_LEARN_PROGRESS_LOG_COLD_CALL,} from '@/store/modules/learn/learn';
import mobileUtils, {isInAppAnd, isInAppIOS} from '@/assets/js/navigationUtils';
import {TOKEN_KEY} from '@/store/modules/auth/auth';
import sessionStorageService from "@/assets/js/localstorage.service";

// const {showMessage, showConfirm, showConfirmCustomBtn} = useAlert();

export const eventCdDcds = {
  Play : '2064001',
  Timeupdate: '2064002',
  Ended : '2064003',
  Coldcall : '2064004',
  ColdcallStop : '2064005',
  AdminColdcall : '2064006',
  Entrance : '2064007',
  Pause : '2064008',
};


const getEventNm = (eventCdDcd) => {
  if (eventCdDcds.Coldcall === eventCdDcd) {
    return '콜드콜 전송';
  } else if (eventCdDcds.ColdcallStop === eventCdDcd) {
    return '중단알림 전송';
  }
  return '';
}

// eventCdDcd => eventCdDcds.Coldcall, eventCdDcds.ColdcallStop
const insertLearnColdCall = async (coldCallTime, progressMeta) => {
  if (coldCallTime > 0) {
    const firstRes = await store.dispatch(`learn/${ACT_INSERT_LEARN_PROGRESS_LOG_COLD_CALL}`, {
      ...progressMeta,
      lastPlaySec: coldCallTime,
      eventCdDcd: eventCdDcds.Coldcall,
      eventNm: getEventNm(eventCdDcds.Coldcall),
      cmptnYn: 'N',
      playSpeed: 1,
      lrnAbortYn: 'N',
      coldCallRound: 1
    });

    let progLogSns = (firstRes || 0);

    const secondRes = await store.dispatch(`learn/${ACT_INSERT_LEARN_PROGRESS_LOG_COLD_CALL}`, {
      ...progressMeta,
      lastPlaySec: coldCallTime,
      eventCdDcd: eventCdDcds.Coldcall,
      eventNm: getEventNm(eventCdDcds.Coldcall),
      cmptnYn: 'N',
      playSpeed: 1,
      lrnAbortYn: 'N',
      coldCallRound: 2
    });
    progLogSns +=  `,${(secondRes || 0)}`;
    return progLogSns;
  }
  return `0,0`;
}


// progressMeta => { progSesnKey, distCrseSn, distChapSn, lrnObjDtlDistSn, objSn, objNm, progRsltSn, lrnRsltSn, division }
// videoMeta = { streamUrl, thumbUrl, contentPlayTime }
export const learnCallNative = async (progressMeta, videoMeta, coldCallTime) => {
  const targetMode = (process.env.NODE_ENV === 'production' ? "PRD" : "DEV");
  // 진도 및 콜드콜 필요 파라미터

  let coldCallMissImgUrl = 'https://hrdcs.kbstar.com/app/img/coldcall_miss_and.png';
  // const coldCallTime = getColdCallTime(0, )
  // uuid, coldCallTime, playbackRate, objectParams
  const progLogSns = await insertLearnColdCall(coldCallTime, progressMeta);

  const progressParams = {
    ...progressMeta,
    authName: TOKEN_KEY,
    authToken: sessionStorageService.getToken(TOKEN_KEY),
    targetMode: targetMode,
    coldCallTime,
    progLogSns,
    eventtype: '{{eventtype}}' ,
    timeline: '{{timeline}}',
    playsec: '{{playsec}}',
    round: '{{round}}',
  };


  if (mobileUtils.iOS()) coldCallMissImgUrl = 'https://hrdcs.kbstar.com/app/img/coldcall_miss.png';
  if (targetMode === 'DEV') coldCallMissImgUrl = 'https://hrdcs.kbstar.com/app/img/coldcall_miss_and_dev.png';

  const initial = {
    myCloudID: progressMeta.lrnObjDtlDistSn,
    viewMode: 'landscape',
    contentTitle: progressParams.objNm || '',
    ...videoMeta,
    educhkInterval: '30',
    educhkFunc: 'progress',
    progJson: JSON.stringify(progressParams),
    coldCallTime,
    seekEnable: 'true',
    // 학습
    checkUrl: 'https://ehrt.kbstar.com/lxp_progress.api',
    coldcallAtypeNotiImgUrl: 'https://hrdcs.kbstar.com/app/img/coldcall_popup.png',
    coldcallAtypeSuccImgUrl: 'https://hrdcs.kbstar.com/app/img/coldcall_success.png',
    coldcallWtypeNotiImgUrl: coldCallMissImgUrl,
    studySuccImgUrl: 'https://hrdcs.kbstar.com/app/img/coldcall_final.png'
  };

  const jsonString = JSON.stringify(initial);
  if (isInAppAnd) window.Android.EhrdVideoInfoReceiveLXP(jsonString);
  else if (isInAppIOS) window.location = "iOSNativeCall://EhrdVideoInfoReceiveLXP?" + (jsonString);
};

//

// const updateLearnColdCall = async (coldCallDivision, progLogSn, progressMeta) => {
//   const res = await store.dispatch(`learn/${ACT_UPDATE_LEARN_PROGRESS_LOG_COLD_CALL}`, {
//     progLogSn: progLogSn, // 현재 반응한 콜드콜,
//     params: {
//       ...progressMeta,
//       coldCallDivision,
//     }
//   });
//
//   // 학습완료 파업 노출
//   // if (res === 1 ) {}
// }
